import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div
      style={{
        maxWidth: "320px",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "20vh",
      }}
    >
      <SEO title="Message Sent" />
      <h1>Success</h1>
      <p>
        Your message was sent.
        <br />
        An ANX Team member will be in touch soon.
      </p>
      <p style={{ paddingTop: "24px" }}>
        <button>
          <Link to="/">Back to Home</Link>
        </button>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
